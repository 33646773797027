// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-tsx": () => import("./../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-credits-tsx": () => import("./../src/pages/credits.tsx" /* webpackChunkName: "component---src-pages-credits-tsx" */),
  "component---src-pages-index-tsx": () => import("./../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-neomix-tsx": () => import("./../src/pages/neomix.tsx" /* webpackChunkName: "component---src-pages-neomix-tsx" */),
  "component---src-pages-overview-tsx": () => import("./../src/pages/overview.tsx" /* webpackChunkName: "component---src-pages-overview-tsx" */),
  "component---src-pages-review-tsx": () => import("./../src/pages/review.tsx" /* webpackChunkName: "component---src-pages-review-tsx" */),
  "component---src-pages-talk-tsx": () => import("./../src/pages/talk.tsx" /* webpackChunkName: "component---src-pages-talk-tsx" */),
  "component---src-templates-artist-page-tsx": () => import("./../src/templates/artist-page.tsx" /* webpackChunkName: "component---src-templates-artist-page-tsx" */)
}

